import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { PortfolioService } from './portfolio.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/services/common.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

export interface Iportfolio {
  id: string;
  name: string;
  inPortfolioSince: string;
  logo: string;
  type?: string;
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent {
  search: string;
  vehicleId: string;
  gpId: string;
  portfolioData: Iportfolio[];
  tempPortfolioData: Iportfolio[];
  noPortfolioFound: boolean = false;
  isLoading: boolean = false;

  constructor(
    private portfolioService: PortfolioService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.commonService.setSecondaryPath([]);
    this.getPortfolioList();
    this.portfolioService.refreshPortfolioListObservable$.subscribe(
      (response: any) => {
        if (response) {
          this.portfolioService.setRefreshPortfolio(false);
          setTimeout(() => {
            this.getPortfolioList();
          }, 100);
        }
      }
    );
  }
  getPortfolioList() {
    this.isLoading = true;
    this.noPortfolioFound = false;
    this.portfolioService.getPortfolioList(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        if (parsedResponse.data.items.length == 0) {
          this.noPortfolioFound = true;
        }
        this.portfolioData = parsedResponse.data.items;
        this.tempPortfolioData = parsedResponse.data.items;
        this.applyFilter();
      },
      error: error => {
        this.isLoading = false;
        this.commonService.errorNotification(
          'Failed to retrieve Portfolio Data'
        );
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  addCompany() {
    this.portfolioService.openPortfolioDrawer();
    this.portfolioService.setPortfolioDetails({
      isEdit: false,
      vehicleId: this.vehicleId,
    });
    this.portfolioService.setRefreshPortfolio(false);
  }
  editPortfolio(data: Iportfolio) {
    this.portfolioService.openPortfolioDrawer();
    this.portfolioService.setPortfolioDetails({
      ...data,
      isEdit: true,
      vehicleId: this.vehicleId,
    });
    this.portfolioService.setRefreshPortfolio(false);
  }
  applyFilter() {
    const serachText = this.search ? this.search.toLocaleLowerCase() : '';
    this.portfolioData = this.tempPortfolioData.filter(item =>
      item.name.toLowerCase().includes(serachText)
    );
  }
  deletePortfolio(portfolio: Iportfolio) {
    const dialogRef = this.dialog.open(DeletePortfolioDialog, {
      data: {
        portfolio,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.portfolioData = this.portfolioData.filter(
          _item => _item.id !== portfolio.id
        );
        this.tempPortfolioData = this.tempPortfolioData.filter(
          _item => _item.id !== portfolio.id
        );
        if (this.tempPortfolioData.length === 0) {
          this.noPortfolioFound = true;
          this.search = '';
        }
        this.commonService.successNotification(
          'Portfolio company deleted successfully'
        );
      } else if (result === 'error') {
        this.commonService.errorNotification(
          'Failed to delete portfolio company'
        );
      }
    });
  }
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class AddCompanyComponent {
  addPortfolioForm: any;
  fileDimensionError: boolean = false;
  fileValidationError: boolean = false;
  isFileSizeExceed: boolean = false;
  submitted: boolean = false;
  base64Image: string | null = null;
  vehicleId: any;
  isEdit: boolean = false;
  portfolioDetails: any;
  @ViewChild('fileInput') fileInput!: ElementRef;
  constructor(
    public portfolioService: PortfolioService,
    public commonService: CommonService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loadAddPortfolioForm();
    this.portfolioService.portfolioDetailsObservable$.subscribe((res: any) => {
      this.fileValidationError = false;
      this.fileDimensionError = false;
      this.isFileSizeExceed = false;
      if (res && res.isEdit) {
        this.addPortfolioForm.reset();
        this.isEdit = res.isEdit;
        this.portfolioDetails = res;
        this.vehicleId = res.vehicleId;
        this.setPortfolioForm(res);
      } else {
        this.vehicleId = res ? res.vehicleId : '';
        this.addPortfolioForm.reset();
        this.base64Image = '';
        this.isEdit = false;
        this.portfolioDetails = '';
      }
    });
  }
  loadAddPortfolioForm() {
    this.addPortfolioForm = this.fb.group({
      name: ['', [Validators.required]],
      inPortfolioSince: ['', [Validators.required]],
    });
  }

  setPortfolioForm(res: any) {
    this.addPortfolioForm.setValue({
      name: res.name,
      inPortfolioSince: res.inPortfolioSince
        ? this.convertToCustomFormat(res.inPortfolioSince)
        : '',
    });
    this.base64Image = res.logo;
  }
  get name() {
    return this.addPortfolioForm.get('name');
  }
  get inPortfolioSince() {
    return this.addPortfolioForm.get('inPortfolioSince');
  }
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
  onFileSelected(event: Event, fileInput: HTMLInputElement): void {
    this.fileValidationError = false;
    this.fileDimensionError = false;
    this.isFileSizeExceed = false;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const validFileTypes = ['image/png', 'image/svg+xml'];
      if (!validFileTypes.includes(file.type)) {
        this.fileValidationError = true;
        fileInput.value = '';
        return;
      }
      // file size validation
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size >= maxSizeInBytes) {
        this.isFileSizeExceed = true;
        fileInput.value = '';
        return;
      }
      this.convertToBase64(file, fileInput);
      // file dimensions validation
      // this.validateImageDimensions(file).then(isValid => {
      //   if (isValid) {
      //     this.convertToBase64(file, fileInput);
      //   } else {
      //     this.fileDimensionError = true;
      //     fileInput.value = '';
      //   }
      // });
    }
  }
  validateImageDimensions(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // skip the condition for svg image
      if (file.type === 'image/svg+xml') {
        resolve(true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          if (img.width >= 200 && img.height >= 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
        img.onerror = () => {
          reject('Unable to load the image for validation.');
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }

  convertToBase64(file: File, fileInput: HTMLInputElement): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Image = reader.result as string;
      if (this.isEdit) {
        this.addPortfolioForm.markAsDirty();
      }
    };
    reader.onerror = error => {
      console.error('Error converting file to Base64:', error);
    };
    reader.readAsDataURL(file);
    fileInput.value = '';
  }

  submitAddPortfolio() {
    if (this.addPortfolioForm.valid) {
      const params: any = {};
      params.name = this.addPortfolioForm.value?.name;
      params.inPortfolioSince = this.addPortfolioForm.value?.inPortfolioSince
        .format
        ? this.addPortfolioForm.value?.inPortfolioSince.format('MM-DD-YYYY')
        : this.addPortfolioForm.value?.inPortfolioSince;
      params.logo = this.base64Image;
      this.submitted = true;
      if (this.isEdit) {
        this.portfolioService
          .updatePortfolio(this.portfolioDetails.id, params)
          .subscribe({
            next: (response: any) => {
              if (response) {
                this.commonService.successNotification(
                  'Portfolio company updated successfully'
                );
                this.portfolioService.setRefreshPortfolio(true);
              }
            },
            error: error => {
              this.submitted = false;
              this.commonService.errorNotification(
                'Something went wrong. Please inform your ACE support contact. Thank you!'
              );
            },
            complete: () => {
              this.submitted = false;
              this.portfolioService.closePortfolioDrawer();
              this.addPortfolioForm.reset();
            },
          });
      } else {
        this.portfolioService.addPortfolio(this.vehicleId, params).subscribe({
          next: (response: any) => {
            if (response) {
              this.commonService.successNotification(
                'Portfolio company added successfully'
              );
              this.portfolioService.setRefreshPortfolio(true);
            }
          },
          error: error => {
            this.submitted = false;
            this.commonService.errorNotification(
              'Something went wrong. Please inform your ACE support contact. Thank you!'
            );
          },
          complete: () => {
            this.submitted = false;
            this.portfolioService.closePortfolioDrawer();
            this.addPortfolioForm.reset();
            this.base64Image = '';
          },
        });
      }
    }
  }
  convertToISOString(dateString: string): any {
    if (dateString) {
      const date = new Date(dateString);
      return date.toISOString();
    }
  }
  convertToCustomFormat(dateString: string): any {
    if (dateString) {
      const date = new Date(dateString);
      return date;
      // return date.toString();
    }
  }
  closePopup() {
    this.portfolioService.closePortfolioDrawer();
    this.portfolioService.setRefreshPortfolio(false);
    this.addPortfolioForm.reset();
    this.base64Image = '';
    this.fileValidationError = false;
    this.fileDimensionError = false;
    this.isFileSizeExceed = false;
  }
}

// delete dialog component
@Component({
  selector: 'delete-portfolio-dialog',
  templateUrl: './delete-portfolio-dialog.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class DeletePortfolioDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeletePortfolioDialog>,
    public portfolioService: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  deletePortfolioCompany() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.portfolioService.deletePortfolio(this.data.portfolio.id).subscribe({
      next: _response => {
        this.dialogRef.close('success');
      },
      error: _error => {
        this.dialogRef.close('error');
      },
      complete: () => {
        this.loading = false;
        this.dialogRef.disableClose = false;
      },
    });
  }
}
