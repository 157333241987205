<div class="transaction-detail">
    <div class="transaction-subheader">
        <ng-container>
            <div class="title-content">
                <span class="image-border" (click)="navigateBack()">
                    <img src="assets/images/back_icon.svg" height="24px" width="24px" />
                </span>
                <!-- <span class="subheader-label">{{lpDetails.name}}</span> -->
                <!-- <span class="badge-investor-id">{{lpDetails.investorId ? lpDetails.investorId : ''}}</span> -->
            </div>
        </ng-container>
    </div>
    <div class="transaction-section">
        <ng-container *ngFor="let item of transactionDetails">
            <div class="detail-item">
                <div class="detail-key">{{item.key}}</div>
                <div class="detail-value" *ngIf="!isObject(item.value)">
                    <ng-container
                        *ngIf="dateFields.includes(item.key)">{{commonService.formateDate(item.value)}}</ng-container>
                    <ng-container *ngIf="!dateFields.includes(item.key)">{{item.value ? item.value :'-'}}</ng-container>
                </div>
            </div>
            <ng-container *ngIf="isObject(item.value)">
                <ng-container *ngFor="let subItem of objectToArrayOfObjects(item.value)">
                    <div class="sub-detail-item">
                        <div class="sub-detail-key">{{subItem.key}}</div>
                        <div class="sub-detail-value">
                            <ng-container>{{subItem.value? subItem.value :'-'}}</ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>