const moniteTheme = {
  palette: {
    primary: {
      main: '#111111',
    }
  },
  spacing: (factor: number) => `${factor * 8}px`,
  typography: {
    fontSize: 14,
    fontFamily: 'Inter',
    h3: {
      fontSize: 24,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#111111',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#111111',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '10px',
          borderRadius: '12px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        input: {
          padding: '8px !important',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-8px',
        },
        shrink: {
          top: '0px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          border: '0',
          maxWidth: '1920px',
          minWidth: '1132px',
        },
        columnHeaders: {
          fontWeight: 'bold',
          textTransform: "capitalize",
          height: '45px !important'
        },
        columnHeader: {
          height: '45px !important'
        },
        columnHeaderTitle: {
          fontSize: '12px',
          color: '#828282',
        },
        row: {
          border: '0',
          minHeight: '68px !important',
          '&:hover': {
            backgroundColor: 'none',
          }
        },
        cell: {
          height: '68px',
          border: '0px',
          borderBottom: '1px solid #E9E9E9',
          cursor: 'pointer',
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          }
        },
        footerContainer: {
          minHeight: '45px'
        },
      },
    },
  },
};

export default moniteTheme;
