<ng-container *ngIf="!isShowDetails">
  <ng-container *ngIf="!noQuarterlyDataFound; else noDataTemplate">
    <div class="quarterly-data">
      <div class="quaterly-header">
        <ng-container *ngIf="!isLoading; else searchLoader">
          <mat-form-field class="quater-search-field">
            <img src="../../assets/images/search.svg" alt="search" matPrefix />
            <input
              matInput
              placeholder="Search"
              (input)="applyFilter()"
              [(ngModel)]="search"
              autocomplete="off" />
            <button
              class="clear-btn"
              *ngIf="search"
              matSuffix
              (click)="search = ''; applyFilter()">
              <img
                src="../../../assets/images/cross_icon_grey.svg"
                height="20px"
                width="20px"
                alt="clear" />
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!isLoading; else filterLoader">
          <mat-form-field appearance="fill" class="status-field">
            <mat-select
              placeholder="Status"
              #select
              [(value)]="filterValue"
              (selectionChange)="applyFilter()">
              <mat-option
                value="1"
                class="filter-item"
                [ngClass]="{ 'value-not-selected': !filterValue }"
                >Ongoing</mat-option
              >
              <mat-option
                value="2"
                class="filter-item"
                [ngClass]="{ 'value-not-selected': !filterValue }"
                >Completed</mat-option
              >
              <div class="dropdown-clear-btn" *ngIf="filterValue">
                <button
                  type="button"
                  class="btn-clear"
                  (click)="
                    this.filterValue = null; select.close(); applyFilter()
                  ">
                  Clear
                </button>
              </div>
            </mat-select>
            <img
              class="arrow-down"
              [ngClass]="{ open: select.panelOpen }"
              src="../../assets/images/arrow-down.svg"
              matSuffix />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!isLoading; else addQuaterLoader">
          <button
            class="create-new-btn"
            mat-flat-button
            type="button"
            (click)="addQuater()"
            [disabled]="disableAddQuarter">
            <mat-icon class="icon">add</mat-icon>Add quarter
          </button>
        </ng-container>
      </div>
      <div class="table-section">
        <div class="quarterly-data-table">
          <ng-container *ngIf="!isLoading">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              matSortActive="year"
              matSortDirection="desc"
              matSortDisableClear
              class="center-table">
              <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Quarter
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  (click)="showDetails(element); $event.preventDefault()">
                  <div class="badge-circle">
                    <span class="badge-text">
                      {{ element.year }} Q{{ element.quarter }}</span
                    >
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef>Progress</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  (click)="showDetails(element); $event.preventDefault()">
                  <div class="progress-container">
                    <div class="progress-bar">
                      <span
                        class="progress-bar-grow"
                        [ngClass]="{ 'zero-bar': element.completedItems == 0 }"
                        [ngStyle]="{
                          width: calulateBarPercentage(
                            element.totalItems,
                            element.completedItems
                          )
                        }">
                      </span>
                    </div>
                    <span class="progress-text"
                      >{{ element.completedItems }}/{{
                        element.totalItems
                      }}</span
                    >
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  (click)="showDetails(element); $event.preventDefault()">
                  <ng-container *ngIf="element.status !== null; else nullValue">
                    <div
                      class="quarter-status-badge ongoing"
                      *ngIf="element.totalItems !== element.completedItems">
                      <img
                        src="../../../../assets/images/clock_icon.svg"
                        alt="investor-icon"
                        height="16px"
                        width="16px" />
                      Ongoing
                    </div>
                    <div
                      class="quarter-status-badge completed"
                      *ngIf="
                        element.totalItems === element.completedItems &&
                        element.totalItems
                      ">
                      <img
                        src="../../../../assets/images/circle_tick_green_icon.svg"
                        alt="investor-icon"
                        height="16px"
                        width="16px" />
                      Completed
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>
                  <ng-container>
                    <div class="count-details">
                      {{ dataSource.filteredData.length }}
                    </div>
                  </ng-container>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="mat-menu-icon"
                    [matMenuTriggerFor]="menu"
                    mat-icon-button>
                    <img
                      src="../../../assets/images/three_dot_menu_icon.svg"
                      alt="menu_icon"
                      height="20px"
                      width="20px" />
                  </div>
                  <mat-menu
                    class="portfolio-mat-menu"
                    #menu="matMenu"
                    xPosition="before"
                    portfolio-action-position>
                    <div
                      mat-menu-item
                      [ngClass]="{ 'no-edit-allow': element.totalItems === 0 }"
                      (click)="showDetails(element); $event.preventDefault()">
                      <img
                        src="../../../assets/images/edit_icon.svg"
                        alt="delete_icon"
                        height="20px"
                        width="20px" />
                      Edit
                    </div>
                    <div
                      mat-menu-item
                      (click)="openDeleteQuarterlyDialog(element)">
                      <img
                        src="../../../assets/images/delete_icon.svg"
                        alt="delete_icon"
                        height="20px"
                        width="20px" />
                      Delete
                    </div>
                  </mat-menu>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ 'no-click': !row.totalItems }"></tr>
            </table>
          </ng-container>
          <ng-container *ngIf="isLoading">
            <table
              mat-table
              [dataSource]="[1, 2, 3, 4, 5]"
              class="investor-table">
              <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef>Quarter</th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader
                    count="1"
                    appearance="line"
                    [theme]="{
                      width: '80px',
                      height: '24px',
                      marginBottom: 0
                    }">
                  </ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef>Progress</th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader
                    count="1"
                    appearance="line"
                    [theme]="{
                      width: '165px',
                      height: '24px',
                      marginBottom: 0
                    }">
                  </ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader
                    count="1"
                    appearance="line"
                    [theme]="{
                      width: '80px',
                      height: '24px',
                      marginBottom: 0
                    }">
                  </ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>
                  <ngx-skeleton-loader
                    count="1"
                    appearance="line"
                    [theme]="{
                      width: '40px',
                      height: '15px',
                      marginBottom: 0
                    }"></ngx-skeleton-loader>
                </th>
                <td mat-cell *matCellDef="let element"></td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noDataTemplate>
    <div class="wrapper">
      <div class="no-portfolio-companies">
        <div class="icon">
          <img
            src="../../../assets/images/no_quarter_data_icon.svg"
            alt="quarter_icon" />
        </div>
        <div class="text">
          <div class="heading">No quarters added...</div>
          <div class="support-text">
            Quickly add a quarter by clicking on the button below
          </div>
        </div>
        <div class="action">
          <button mat-flat-button (click)="addQuater()">
            <mat-icon class="icon-btn">add</mat-icon>Add quarter
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<quarter-data-details
  *ngIf="isShowDetails"
  [vehicleId]="vehicleId"
  [quarterlyData]="quarterlyData"
  (backClick)="hideDetails()"></quarter-data-details>

<ng-template #nullValue>-</ng-template>

<ng-template #searchLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '400px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #filterLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '140px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #addQuaterLoader>
  <div style="margin-left: auto">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{
        width: '160px',
        height: '38px',
        marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
<ng-template #searchLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '390px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #filterLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '85px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #addQuaterLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '85px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
