import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionsService {

  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
  ) { }
  getTransactions(vehicleId: string, sortBy: string, orderBy: SortDirection, pgIndex: Number, pgSize: number, params?: { [key: string]: any }){ 
    const queryParams = {
      page: Number(pgIndex) + 1,
      pageSize: pgSize,
      sortBy: sortBy,
      order: orderBy,
      ...params
    }; 

    const url = this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/bank-accounts-v2/transactions`,
      false
    );

    return this.apiHttpService.get(url, { params: queryParams });
  }

  getExcelFile(vehicleId: string, sortBy: string, orderBy: SortDirection, pgIndex: Number, pgSize: number, params?: { [key: string]: any }){ 
    const queryParams = {
      page: Number(pgIndex) + 1,
      pageSize: pgSize,
      sortBy: sortBy,
      order: orderBy,
      ...params
    }; 
    
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-accounts-v2/transactions`,
        false
      ), { responseType: 'arraybuffer', params: queryParams }
    );
  }
}
