<div class="unchanges-annotation-document">
  <div class="content">
    <div class="heading">
      You have unsaved changes
      <button (click)="dialogRef.close()" [disabled]="loading">
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" />
      </button>
    </div>
    <div class="form-field">
      <div class="description">Do you want to discard or save the changes?</div>
    </div>
  </div>
  <div class="action">
    <button mat-flat-button class="cancel" (click)="discard()">Discard</button>
    <button
      mat-flat-button
      class="save"
      (click)="saveAnnotation()"
      [disabled]="loading">
      <ng-container *ngIf="!loading; else whiteSpinner"> Save </ng-container>
    </button>
  </div>
</div>
<ng-template #whiteSpinner>
  <app-spiner-loader [color]="'white'"></app-spiner-loader>
</ng-template>
