import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  NgZone,
  HostListener,
} from '@angular/core';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { PopstateService } from './popstate.service';
@Component({
  selector: 'app-pdf-apryse',
  templateUrl: './pdf-apryse.component.html',
  styleUrls: ['./pdf-apryse.component.scss'],
})
export class PdfApryseComponent implements OnChanges, AfterViewInit {
  @ViewChild('viewer') viewer!: ElementRef;

  @Input() filePath?: any;
  @Input() fileName?: string;
  // @Input() documentAnnotation: string = '';
  @Input() documentAnnotation: any = [];
  @Output() savePdfEvent = new EventEmitter<{
    userId: string;
    annotation: string;
  }>();
  wvInstance?: WebViewerInstance;
  isViewerInitialized = false;
  userId: string;
  viewPortMode = false;
  innerWidth: any;
  userName: string;
  private isDocumentLoaded = false;
  private annotationQueue: any[] = [];
  constructor(
    private zone: NgZone,
    private userService: UserService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private popstateService: PopstateService
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event?: any) {
    document.querySelectorAll('mat-sidenav').forEach((el: any) => {
      el.style.transform = 'none';
      el.style.display = 'block';
    });
    if (window.innerWidth > 959 && window.innerWidth < 1280) {
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.marginLeft = '70px';
      });
    }
    if (window.innerWidth >= 1280) {
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.marginLeft = '200px';
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth && this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'translate3d(-100%, 0, 0)';
        el.style.display = 'none';
      });
    }
  }
  ngOnInit() {
    this.commonService.setAnnotationChanges({ annotationChangeDetect: false });
    const { id, name } = JSON.parse(this.userService.getUserConfig());
    this.userId = id;
    this.userName = name;
    this.popstateService.popstate$.subscribe(() => {
      if (this.viewPortMode) {
        this.viewPortMode = false;
        this.onPopState();
      }
    });
    this.commonService.disablePdfToolbarObservable$.subscribe(
      (status: boolean) => {
        const shadowRoot: any =
          document.querySelector('apryse-webviewer')!.shadowRoot;
        if (status && this.isDocumentLoaded) {
          shadowRoot
            .querySelectorAll('.ModularHeader')
            .forEach((element: any) => {
              if (element) {
                element.style.pointerEvents = 'none';
              }
            });
        } else {
          shadowRoot
            .querySelectorAll('.ModularHeader')
            .forEach((element: any) => {
              if (element) {
                element.style.pointerEvents = 'auto';
              }
            });
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.initializeViewer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['filePath'] && this.filePath) {
    //   this.loadDocumentIfReady(this.filePath);
    // }
    if (this.isViewerInitialized) {
      if (this.isDocumentLoaded) {
        this.mergeAnnotations(this.documentAnnotation);
      } else {
        this.annotationQueue.push(...this.documentAnnotation);
      }
    }
  }

  private initializeViewer(): void {
    WebViewer(
      {
        licenseKey: environment.pdfApryseViewerLicenseKey,
        path: 'assets/apryse-pdf-lib',
        css: 'assets/apryse-pdf.css',
        // licenseKey:
        //   'demo:1734934883212:7ea752de0300000000d3a8d83184fb7897afeb10bbe8c96015848cc297',
      },
      this.viewer.nativeElement
    ).then(instance => {
      this.wvInstance = instance;
      this.isViewerInitialized = true;
      const { UI } = instance;
      const { documentViewer, annotationManager } = instance.Core;
      const header = instance.UI.getModularHeader('default-top-header');
      const existingItems = header.getItems();
      annotationManager.setCurrentUser(this.userName);
      // disable toolbar groups
      // instance.UI.disableElements(['toolbarGroup-Shapes']);
      // instance.UI.disableElements(['toolbarGroup-Edit']);
      // instance.UI.disableElements(['toolbarGroup-Insert']);
      // instance.UI.disableElements(['toolbarGroup-Annotate']);
      // instance.UI.disableElements(['toolbarGroup-FillAndSign']);
      instance.UI.disableElements(['toolbarGroup-Forms']);
      // instance.UI.disableElements(['searchPanelToggle']);
      // instance.UI.disableElements(['notesPanelToggle']);
      // instance.UI.disableElements(['save-pdf-Button']);
      // instance.UI.disableElements(['ellipseToolButton']);
      // instance.UI.disableElements(['menuButton']);
      instance.UI.disableElements(['fullscreenButton']);
      instance.UI.disableElements(['addReplyAttachmentButton']);
      instance.UI.disableElements(['fileAttachmentButton']);
      instance.UI.disableElements(['stampToolButton']);
      instance.UI.disableElements(['addImageContentToolGroupButton']);
      // instance.UI.disableElements(['addParagraphToolGroupButton']);
      instance.UI.enableFeatures([instance.UI.Feature.ContentEdit]);
      // Define the maximize/minimize stateful button
      const maximizeMinimizeButton = {
        type: 'statefulButton',
        initialState: 'Maximize',
        states: {
          Maximize: {
            img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19ZM9.5 4L7.45711 6.04311L9.7071 8.2929L8.2929 9.7071L6.04311 7.4571L4 9.5V4H9.5Z" fill="#868E96"/></svg>',
            title: 'Maximize',
            onClick: (update: any, activeState: any) => {
              this.handleViewPortMode();
              update('Minimize');
            },
          },
          Minimize: {
            img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19Z" fill="#868E96"/><path d="M6.24992 7.66392L4.20703 9.70703L9.70703 9.70703L9.70703 4.20703L7.66392 6.24993L5.41413 3.99993L3.99993 5.41413L6.24992 7.66392Z" fill="#868E96"/></svg>',
            title: 'Minimize',
            onClick: (update: any, activeState: any) => {
              this.handleViewPortMode();
              update('Maximize');
            },
          },
        },
        dataElement: 'maximizeMinimizeButton',
      };
      const updatedItems = [
        ...existingItems,
        {
          type: 'customButton',
          dataElement: 'save-pdf-Button',
          label: 'Save PDF',
          title: 'Save PDF',
          class: 'custom-save-pdf-button',
          onClick: () => {
            this.zone.run(() => this.saveAnnotations());
          },
        },
      ];
      header.setItems([...updatedItems, maximizeMinimizeButton]);
      documentViewer.updateView();
      instance.UI.loadDocument(this.filePath, { filename: this.fileName });
      this.setupDocumentViewerEvents(instance);
      // this.loadDocumentIfReady(this.filePath);
    });
  }

  private setupDocumentViewerEvents(instance: WebViewerInstance): void {
    const { documentViewer, annotationManager } = instance.Core;

    documentViewer.addEventListener('annotationsLoaded', () => {
      // console.log('annotation');
    });

    documentViewer.addEventListener('documentLoaded', async () => {
      instance.UI.setZoomLevel('85%');
      this.isDocumentLoaded = true;
      // this.adjustViewerLayout();
      // save or import the annotation
      // if (this.documentAnnotation.length > 0) {
      //   this.documentAnnotation.forEach(async (anno: any) => {
      //     const annotations: any =
      //       await annotationManager.importAnnotationCommand(
      //         anno.annotation ? anno.annotation : ''
      //       );
      //     annotations.forEach((annotation: any) => {
      //       annotationManager.redrawAnnotation(annotation);
      //     });
      //   });
      // }
      // if (this.documentAnnotation.length > 0) {
      //   const promises = this.documentAnnotation.map(async (anno: any) => {
      //     try {
      //       const annotations = await annotationManager.importAnnotationCommand(
      //         anno.annotation ? anno.annotation : ''
      //       );
      //       annotations.forEach(annotation => {
      //         annotationManager.redrawAnnotation(annotation);
      //       });
      //     } catch (error) {
      //       console.error('Error processing annotation:', error);
      //     }
      //   });
      //   await Promise.allSettled(promises);
      // }
      if (this.annotationQueue.length > 0) {
        console.log('annotationQueue', this.annotationQueue);
        const setannotation = await this.mergeAnnotations(this.annotationQueue);
        this.annotationQueue = [];
      }
    });

    annotationManager.addEventListener(
      'annotationChanged',
      async (annotations, action, { imported }) => {
        if (imported) return;
        // annotations.forEach((annotation: any) => {
        //   annotation.setCustomData('username', this.userName);
        //   annotation.setCustomData('userid', this.userId);
        //   if (
        //     annotation &&
        //     annotation.Subject === 'Note' &&
        //     annotation.getContents()
        //   ) {
        //     this.zone.run(() => this.saveAnnotations());
        //   }
        // });
        // const xfdfString = await annotationManager.exportAnnotationCommand();
        const xfdfString = await annotationManager.exportAnnotations();
        this.commonService.setAnnotationChanges({
          userId: this.userId,
          annotation: xfdfString,
          annotationChangeDetect: true,
          fileName: this.fileName,
        });
      }
    );
  }
  private async mergeAnnotations(documentAnnotations: any[]): Promise<void> {
    if (documentAnnotations.length > 0) {
      const { annotationManager } = this.wvInstance?.Core!;
      // first way
      // const promises = documentAnnotations.map(async (anno: any) => {
      //   try {
      //     const annotations = await annotationManager.importAnnotationCommand(
      //       anno.annotation ? anno.annotation : ''
      //     );
      //     annotations.forEach((annotation: any) => {
      //       annotationManager.redrawAnnotation(annotation);
      //     });
      //   } catch (error) {
      //     console.error('Error processing annotation:', error);
      //   }
      // });
      // second way
      const promises = documentAnnotations.map(async (anno: any) => {
        try {
          if (anno.annotation) {
            await annotationManager.importAnnotations(anno.annotation);
          }
        } catch (error) {
          console.error('Error processing annotation:', error);
        }
      });
      await Promise.allSettled(promises);
    }
  }

  private loadDocumentIfReady(filePath?: string): void {
    if (this.isViewerInitialized && filePath) {
      this.loadDocument(filePath);
    }
  }

  private loadDocument(blobOrPath: string): void {
    if (this.wvInstance) {
      const { documentViewer } = this.wvInstance.Core;
      documentViewer.loadDocument(blobOrPath);
      documentViewer.addEventListener('documentLoaded', () => {});
    } else {
      console.error(
        'WebViewer instance is not available to load the document.'
      );
    }
  }

  async saveAnnotations() {
    if (this.wvInstance && this.wvInstance.Core) {
      const { annotationManager } = this.wvInstance.Core;
      // save the api
      // const xfdfString = await annotationManager.exportAnnotationCommand();
      // console.log('save sfdf', xfdfString);
      // save the file
      const xfdfString = await annotationManager.exportAnnotations();
      // this.commonService.setAnnotationChanges({
      //   annotationChangeDetect: false,
      // });
      this.savePdfEvent.emit({ userId: this.userId, annotation: xfdfString });
    }
  }

  private adjustViewerLayout(): void {
    if (this.wvInstance) {
      const { documentViewer } = this.wvInstance.Core;
      documentViewer.updateView();
    }
  }
  handleViewPortMode(): void {
    this.viewPortMode = !this.viewPortMode;
    if (this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'translate3d(-100%, 0, 0)';
      });
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.zIndex = '0';
        el.style.marginLeft = '0px';
      });
    } else {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'none';
        el.style.display = 'block';
      });
      if (window.innerWidth > 959 && window.innerWidth < 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '70px';
        });
      }
      if (window.innerWidth >= 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '200px';
        });
      }
    }
    this.cdr.detectChanges();
  }
}
