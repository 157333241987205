import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { MoniteService } from '../monite.service';
import { UserService } from 'src/app/core/services/user.service';
import moniteTheme from '../monite.theme';

@Component({
  selector: 'app-counterparts',
  template: `
    <div *ngIf="!moniteIdMissing; else moniteIdMissingerror"></div>
    <div *ngIf="!moniteUserIdMissing; else userIdMissingerror"></div>
    <ng-template #moniteIdMissingerror>
        <app-service-not-available [description]="serviceNotAvailableDesc"></app-service-not-available>
    </ng-template>
    <ng-template #userIdMissingerror>
        <app-service-not-available [description]="accessNotAvailableDesc"></app-service-not-available>
    </ng-template>
  `,
  styleUrls: ['./counterparts.component.scss']
})
export class CounterpartsComponent {

  vehicleId: string;
  userId: string;
  entityId: string;
  entiryUserId: string;
  serviceNotAvailableDesc =
    'This vehicle has not yet been configured for the use this service. Please contact support at';
  accessNotAvailableDesc =
    'This user has not yet been configured for the use this service. Please contact support at';
  moniteIdMissing: boolean = false;
  moniteUserIdMissing: boolean = false;
  selectVehicle: any;

  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private moniteService: MoniteService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Counterparts']);
    }, 0);
    this.userService.userDataObservable$.subscribe((res: any) => {
      if (res) {
        this.userId = res.id;
      }
    });
    this.userService.selectedVehicleObservable$.subscribe((res: any) => {
      if (res) {
        this.selectVehicle = res;
      }
    });
    this.loadMoniteSDK()
  }

  getEntityUser() {
    this.moniteService
      .getMoniteIds(this.userId, this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (!parsedResponse.entityId) {
            this.moniteIdMissing = true
            return
          }
          if (!parsedResponse.entityUserId) {
            this.moniteUserIdMissing = true
            return
          }
          this.entityId = parsedResponse.entityId
          this.entiryUserId = parsedResponse.entityUserId
          this.loadMoniteSDK();
        }
      });
  }

  private loadMoniteSDK(): void {
    if (!(this.selectVehicle && this.selectVehicle.moniteEntityId && this.selectVehicle.moniteEntityUserId)) {
      this.moniteIdMissing = !!this.selectVehicle.moniteEntityId;
      this.moniteUserIdMissing = !!this.selectVehicle.moniteEntityUserId;
      return
    }
    this.entityId = this.selectVehicle.moniteEntityId;
    this.entiryUserId = this.selectVehicle.moniteEntityUserId;
    const script = this.renderer.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.sandbox.monite.com/monite-app.js';
    this.renderer.appendChild(document.body, script);

    script.onload = () => {
      this.addMoniteApp(this.entityId, this.entiryUserId);
    };
  }

  // Add the Monite `<monite-app>` element to the DOM
  private addMoniteApp(entityId: string, entityUserId: string): void {
    const moniteApp = this.renderer.createElement('monite-app');

    this.renderer.setAttribute(moniteApp, 'entity-id', `${entityId}`);
    this.renderer.setAttribute(
      moniteApp,
      'api-url',
      'https://api.sandbox.monite.com/v1'
    );
    this.renderer.setAttribute(moniteApp, 'component', 'counterparts');
    this.renderer.setAttribute(moniteApp, 'basename', '/');

    // Add the theming script
    const themeScript = this.renderer.createElement('script');
    themeScript.setAttribute('slot', 'theme');
    themeScript.setAttribute('type', 'application/json');
    themeScript.textContent = JSON.stringify(moniteTheme);
    this.renderer.appendChild(moniteApp, themeScript);

    // Add the custom CSS for Monite component
    const styleTag = this.renderer.createElement('style');
    styleTag.textContent = `
        * {
          "font-family": "Inter !important"
        }
        #monite-app-root {
          height: calc(100vh - 85px);
        }
        .Monite-Counterparts-Title{
          margin: 0;
          inset: 0;
          height: 60px;
        }
       .Monite-Counterparts-Title h2 {
          visibility: hidden;
          font-size: 0;
       }
       .MuiTable-root {
          font-family: 'Inter' !important;
        }
        .Monite-CounterpartsTable{
          padding: 0;
          height: calc(100vh - 150px)
        }
        .Monite-CounterpartTypeFilter{
          margin: 10px 0;
        }
        .MuiDataGrid-filler{
          display: none;
        }
        .MuiDataGrid-row:hover{
          background-color: unset !important;
        }
        .Monite-PayableDetails-Header .MuiTypography-h3{
          font-size: 24px;
        }
        .Monite-CounterpartFilters{
          border-bottom: 1px solid #E9E9E9;
          margin: 0;
        }
     `;
    moniteApp.shadowRoot.appendChild(styleTag);

    // Add the fetch-token script
    const fetchTokenScript = this.renderer.createElement('script');
    fetchTokenScript.setAttribute('slot', 'fetch-token');
    fetchTokenScript.textContent = `async function fetchToken() {
      const token = localStorage.getItem('access_token');
        if (!token) {
          console.log("token not found in localStorage");
        }
      const response = await fetch("${this.moniteService.getEntityUserAuthUrl(entityUserId)}", {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + token
        }
      });
      return await response.json();
    }`
    this.renderer.appendChild(moniteApp, fetchTokenScript);
    this.renderer.appendChild(this.el.nativeElement, moniteApp);
  }
}